<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <!-- 登录 -->
      <img
        v-if="!userInfo"
        class="aboutbg1 pc"
        :src="require('@/assets/img/userbg1.png')"
      />
      <img
        v-if="!userInfo"
        class="aboutbg1 h5"
        :src="require('@/assets/imgh5/homebg1.png')"
      />

      <div class="login-item" v-if="!userInfo">
        <!-- 登录 -->
        <div class="nei-item" v-show="showType == 1">
          <div class="login-title">账号登录</div>
          <el-form
            style="width: 100%"
            :model="loginForm"
            :rules="loginRules"
            ref="loginForm1"
          >
            <el-form-item prop="userName">
              <input
                class="login-input"
                v-model="loginForm.userName"
                placeholder="请输入账号"
              />
            </el-form-item>
            <el-form-item prop="password">
              <input
                class="login-input"
                type="password"
                @keyup="
                  loginForm.password = loginForm.password.replace(
                    /[\u4E00-\u9FA5]/g,
                    ''
                  )
                "
                v-model="loginForm.password"
                placeholder="请输入密码"
              />
            </el-form-item>
            <el-form-item>
              <el-button class="t-btn" type="text" @click="handleChangeType(2)"
                >注册</el-button
              >
            </el-form-item>
          </el-form>
          <div class="sub-btn" @click="submitLogin">登录</div>
        </div>
        <!-- 注册 -->
        <div class="nei-item" v-show="showType == 2">
          <div class="login-title">账号注册</div>
          <el-form
            style="width: 100%"
            :model="registerForm"
            :rules="registerRules"
            ref="registerForm1"
          >
            <el-form-item prop="userName">
              <input
                class="login-input"
                v-model="registerForm.userName"
                placeholder="请输入账号"
              />
            </el-form-item>
            <el-form-item prop="password">
              <input
                class="login-input"
                type="password"
                @keyup="
                  registerForm.password = registerForm.password.replace(
                    /[\u4E00-\u9FA5]/g,
                    ''
                  )
                "
                v-model="registerForm.password"
                placeholder="请输入密码"
              />
            </el-form-item>
            <el-form-item prop="code">
              <div class="code-item">
                <input
                  class="login-input"
                  @keyup="
                    registerForm.code = registerForm.code.replace(
                      /[\u4E00-\u9FA5]/g,
                      ''
                    )
                  "
                  v-model="registerForm.code"
                  placeholder="验证码"
                />
                <img class="code-img" :src="codeImg" />
                <img
                  class="re-img"
                  @click="getCodeImg"
                  :src="require('@/assets/img/reload.png')"
                />
              </div>
            </el-form-item>
            <el-form-item>
              <el-button class="t-btn" type="text" @click="handleChangeType(1)"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
          <div class="sub-btn" @click="submitRegister">注册</div>
          <div class="agree">
            <el-checkbox v-model="registerForm.agree"
              >我已经阅读并同意<span
                style="cursor: pointer"
                @click="handleGoHtml('terms')"
                >《用户注册服务协议》</span
              >和<span style="cursor: pointer" @click="handleGoHtml('policy')"
                >《九玩游戏隐私政策》</span
              ></el-checkbox
            >
          </div>
        </div>
      </div>

      <!-- 用户信息 -->
      <img
        v-if="userInfo"
        class="aboutbg1 pc"
        :src="require('@/assets/img/userbg2.png')"
      />
      <img
        v-if="userInfo"
        class="aboutbg1 h5"
        :src="require('@/assets/imgh5/userbg2.png')"
      />

      <div class="contain" v-show="userInfo">
        <img class="yuan-img" :src="require('@/assets/img/yuan.png')" />
        <!-- <div class="out-btn h5" @click="handleChangeType(3)">充值</div> -->
        <div class="info">
          <div class="info-line">
            <div>
              用户账号:<span style="margin-left: 10px">{{
                userInfo ? userInfo.account : ""
              }}</span>
            </div>
          </div>
          <div class="info-line">
            <div>账户余额:<span style="margin-left: 10px">0</span></div>
            <!-- <div class="out-btn pc" @click="handleChangeType(3)">充值</div> -->
          </div>
          <div class="info-line">
            <div>
              上次登录:<span style="margin-left: 10px">{{
                userInfo ? userInfo.last_login : ""
              }}</span>
            </div>
          </div>
          <div class="info-line">
            <div class="out-btn" @click="handleOut">登出</div>
          </div>
        </div>
      </div>
      <div class="nei-item chong-item" v-show="showType == 3">
        <img class="logo h5" :src="require('@/assets/img/logo.png')" />
        <img
          class="close-icon"
          @click="handleChangeType('')"
          :src="require('@/assets/img/close.png')"
        />
        <el-form
          class="form-item"
          style="width: 100%; margin-top: 20px"
          label-width="100px"
          :model="payForm"
          ref="payForm"
        >
          <el-form-item label="充值账号:" prop="userName">
            <input
              class="login-input"
              readonly
              v-model="payForm.userName"
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item label="确认账号:" prop="password">
            <input
              class="login-input"
              readonly
              v-model="payForm.account"
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item label="充值金额:" prop="money">
            <input
              class="login-input"
              type="number"
              v-model="payForm.code"
              placeholder="请输入金额"
            />
          </el-form-item>
          <el-form-item>
            <el-checkbox-group v-model="checkList" @change="handleChangeCheck">
              <el-checkbox checked label="1">
                <img class="pay-img" :src="require('@/assets/img/zfb.png')" />
              </el-checkbox>
              <el-checkbox label="2">
                <img class="pay-img" :src="require('@/assets/img/wx.png')" />
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <div class="sub-btn">立即支付</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import {
  onMounted,
  onUnmounted,
  ref,
  getCurrentInstance,
  computed,
  watch,
} from "vue";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
// 生成唯一uid
const getUid = () => {
  var d = new Date().getTime(); // 当前时间的时间戳
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; // 性能时间戳
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; // 生成0-16的随机数
    if (d > 0) {
      // 使用时间戳的高位值
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // 如果性能时间戳不存在，使用随机数
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
const uuid = ref(getUid());
const type = computed(() => proxy.$route.query.option || 1);
const showType = ref(type.value);
watch(type, (newV) => {
  showType.value = newV;
});
// 获取验证码
const getCodeImg = () => {
  proxy.$axios.get(`/User/getVerifyPic?uuid=${uuid.value}`).then((res) => {
    codeImg.value = res;
  });
};
if (showType.value == 2) {
  getCodeImg();
}

const userInfo = computed(() => store.state.userInfo);
// const userInfo = { id: 1 };
const loginForm = ref({
  userName: "",
  password: "",
});
const loginRules = ref({
  userName: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
  password: [
    { required: true, message: "密码不能为空", trigger: "blur" },
    { min: 6, message: "初始密码不能小于6位", trigger: "blur" },
  ],
});
const registerForm = ref({
  userName: "",
  password: "",
  code: "",
  agree: false,
});
const registerRules = ref({
  userName: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
  password: [
    { required: true, message: "密码不能为空", trigger: "blur" },
    { min: 6, message: "初始密码不能小于6位", trigger: "blur" },
  ],
  code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
});
const payForm = ref({
  userName: "",
  account: "",
  money: "",
});
const checkList = ref([]);
const webId = ref();
const codeImg = ref("");
// 初始化
const initData = async () => {
  let params = {
    host: window.location.host, //"www.9wangame.com",
  };
  proxy.$axios.post("/Index/initData", params).then(async (res) => {
    if (res.code == 200) {
      webId.value = res.data.company_id;
    }
  });
};
initData();
// 切换注册登录
const handleChangeType = (n) => {
  showType.value = n;
  proxy.$router.replace({ path: "/user", query: { option: n } });
  if (n == 2) {
    getCodeImg();
  }
};
// 登录
const submitLogin = () => {
  proxy.$refs.loginForm1.validate((valid) => {
    if (valid) {
      let params = {
        account: loginForm.value.userName,
        password: loginForm.value.password,
        company_id: webId.value,
      };
      proxy.$axios.post("/User/login", params).then((res) => {
        if (res.code == 200) {
          proxy.$message.success("登录成功！");
          store.commit("setUserInfo", res.data);
          loginForm.value = {
            userName: "",
            password: "",
          };
        } else {
          proxy.$message.error(res.message);
        }
      });
    }
  });
};
// 注册
const submitRegister = () => {
  proxy.$refs.registerForm1.validate((valid) => {
    if (valid) {
      if (!registerForm.value.agree) {
        proxy.$message.warning("请先勾选同意相关政策和协议!");
        return;
      }
      let params = {
        account: registerForm.value.userName,
        password: registerForm.value.password,
        company_id: webId.value,
        uuid: uuid.value,
        code: registerForm.value.code,
      };
      proxy.$axios.post("/User/register", params).then((res) => {
        if (res.code == 200) {
          proxy.$message.success("注册成功！快去登录吧！");
          registerForm.value = {
            userName: "",
            password: "",
            code: "",
            agree: false,
          };
          handleChangeType(1);
        } else {
          proxy.$message.error(res.message);
        }
      });
    }
  });
};
// 支付方式切换
const handleChangeCheck = (val) => {
  checkList.value = [];
  checkList.value.push(val[1]);
};
// 跳转到协议
const handleGoHtml = (val) => {
  window.location = `/static/${val}.html`;
};
// 登出
const handleOut = () => {
  store.commit("setUserInfo", "");
};
onMounted(() => {
  store.commit("setLoad", false);
});
onUnmounted(() => {});
</script>
<style src="./user.css" scoped></style>
<style scoped>
</style>
  