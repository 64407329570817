<template>
  <div class="home" id="myElement">
    <div class="section section1" id="section1">
      <el-carousel class="carouselimg pc" :interval="6000">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.pic_url" style="width: 100%" />
        </el-carousel-item>
        <img
          :src="require('@/assets/img/banner.png')"
          style="width: 100%; opacity: 0"
          alt=""
        />
      </el-carousel>

      <img class="homebg1 h5" :src="require('@/assets/imgh5/homebg1.png')" />
      <img
        class="div4 h5"
        @click="scrollToAnchor('#section2')"
        :src="require('@/assets/imgh5/downtip.png')"
      />
    </div>

    <div class="section section2" id="section2">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg2.png')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg2.png')" />

      <div class="contain2">
        <img class="picon h5" :src="require('@/assets/imgh5/clogo.png')" />
        <!-- 左边 -->
        <div class="img-list" id="img-list">
          <div
            class="game"
            v-for="(item, index) in gameList"
            :key="index"
            :class="index % 2 == 0 ? 'h5-gr' : ''"
          >
            <img class="h-game" :src="item.game_banner" />

            <div class="hover-div">
              <div class="gname">{{ item.game_name }}</div>
              <div class="desc">
                {{ item.game_desc }}
              </div>
            </div>
          </div>
        </div>
        <!-- 右边 -->
        <img class="picon pc" :src="require('@/assets/img/picon.png')" />
        <div class="btn p-btn" @click="handleGo('/GameList', 2)">
          更多
          <div class="addfont">+</div>
        </div>
      </div>
    </div>

    <div class="section section3" id="section3">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg3.png')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg3.png')" />

      <div class="contain2" style="height: 64%">
        <img class="c-bg pc" :src="require('@/assets/img/cbg.png')" />

        <div class="c-div">
          <div class="c-div-left">
            <div class="c-font1">联系我们</div>
            <div class="c-font2">CONTACT US</div>
          </div>
          <div class="c-div-right">
            <div class="r-item">
              <div class="ri-top">移动业务商务合作：卢小姐</div>
              <div class="ri-bottom">邮箱：lyp@9wangame.com</div>
            </div>
            <div class="r-item">
              <div class="ri-top">商务：徐小姐</div>
              <div class="ri-bottom">邮箱：xv@9wangame.com</div>
            </div>
            <div class="r-item pc" style="border-right: none">
              <div class="ri-bottom last-ri">
                <img class="phone" :src="require('@/assets/img/phone.png')" />
                <span>020-31801213</span>
              </div>
            </div>
          </div>
          <div class="c-div-bottom h5">
            <div class="ri-bottom last-ri">
              <img class="phone" :src="require('@/assets/img/phone.png')" />
              <span>020-31801213</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, getCurrentInstance, ref } from "vue";
import "swiper/css/swiper.css";
// import { throttle } from "lodash";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
const webId = ref();
const gameList = ref([]);
const bannerList = ref([]);
// 初始化
const initData = async () => {
  return new Promise((reslove) => {
    let params = {
      host: window.location.host, //"www.love9w.com",
    };
    proxy.$axios.post("/Index/initData", params).then(async (res) => {
      if (res.code == 200) {
        webId.value = res.data.company_id;
        bannerList.value = res.data.carousels;
        await getGame();
        reslove();
      }
    });
  });
};
initData();
// 获取游戏轮播
const getGame = async () => {
  return new Promise((reslove) => {
    let params = {
      company_id: webId.value,
      type: "index",
    };
    proxy.$axios.post("/Index/getGames", params).then((res) => {
      if (res.code == 200) {
        gameList.value = res.data;
        reslove();
      }
    });
  });
};
// 跳转页面
const handleGo = (val, n) => {
  proxy.$router.push({
    path: val,
  });
  store.commit("setActiveIndex", n);
};
const scrollToAnchor = (id) => {
  document.querySelector(id).scrollIntoView({ behavior: "smooth" });
};
onMounted(() => {
  store.commit("setLoad", false);
});

onUnmounted(() => {});
</script>
<style src="./home.css"></style>
<style scoped>
</style>
