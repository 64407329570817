<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img class="aboutbg1 pc" :src="require('@/assets/img/gamebg1.png')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/gamebg1.png')" />

      <div class="top-font top-font1">关于</div>
      <div class="ct-font">
        <div>JIUWAN GAMES</div>
      </div>
      <div class="top-font top-font2">我们</div>
    </div>
    <div class="section2 section" id="section2">
      <img class="aboutbg2 pc" :src="require('@/assets/img/autobg2.png')" />
      <img class="aboutbg2 h5" :src="require('@/assets/imgh5/gamebg2.png')" />

      <div class="contain">
        <!-- 左边 -->
        <div class="a-logo">
          <img
            class="alogoimg pc"
            :src="require('@/assets/img/aboutlogo.png')"
          />
          <img
            class="alogoimg h5"
            :src="require('@/assets/imgh5/aboutlogo.png')"
          />
        </div>
        <!-- 右边 -->
        <div class="a-text">
          <p>
            杭州九玩网络科技有限公司是一家以网页游戏、手机游戏为核心，集合研发、运营、发行为一体的综合性互动娱乐企业。
          </p>
          <p>
            公司一直秉承“品牌、创新、完美”的经营管理理念，强调创新的游戏设计和优秀传统文化的结合，以丰富的精品IP为企业核心竞争力，深度挖掘广大玩家的需求，致力发展成为一家布局全球化、业务多元化、产品多样化的生态型游戏公司。
          </p>
          <p>
            已经成功推出了《怒火一刀》、《狂暴传奇》、《传奇霸主》、《御剑八荒》、《地城战纪》、《江山荣耀》等深受玩家欢迎的产品。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
onMounted(() => {
  store.commit("setLoad", false);
});
onUnmounted(() => {});
</script>
<style src="./auto.css" scoped></style>
<style scoped>
</style>
