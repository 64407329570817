<template>
  <div class="affix-container pc">
    <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
    >
      <el-menu-item class="logobtn" index="1">
        <el-image class="imgw145" :src="require('@/assets/img/logo.png')" />
      </el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="1">
        首页
        <img
          v-show="activeIndex == '1'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="2" style="position: relative" class="about-menu">
        <div>产品中心</div>
        <img
          v-show="activeIndex == '2'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="3"
        >关于我们
        <img
          v-show="activeIndex == '3'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="4"
        >加入我们
        <img
          v-show="activeIndex == '4'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="5"
        >客服中心
        <img
          v-show="activeIndex == '5'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="6"
        >用户中心
        <img
          v-show="activeIndex == '6'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="7"
        >家长监护
        <img
          v-show="activeIndex == '7'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <div class="flex-grow" />
      <div class="right-login">
        <img
          class="tbbgimg"
          v-if="!userInfo"
          :src="require('@/assets/img/tbbg.png')"
        />
        <div class="l-text" v-if="!userInfo">
          <div class="btn-text" @click="handleGoUser(1)">登录</div>
          <div class="btn-text" style="color: #1c75d9" @click="handleGoUser(2)">
            注册
          </div>
        </div>
        <div
          v-else
          class="l-text"
          style="position: static; color: #1c75d9; min-width: 180px"
        >
          您好，{{ userInfo.account }}
        </div>
      </div>
    </el-menu>
  </div>
  <!-- H5 顶部 -->
  <div class="h5-top h5">
    <img
      v-if="!menuShow"
      class="top-more"
      @click="handleShowMenu"
      :src="require('@/assets/imgh5/more.png')"
    />
    <img
      v-else
      class="top-more"
      @click="
        () => {
          menuShow = false;
        }
      "
      :src="require('@/assets/imgh5/close.png')"
    />
    <img class="top-icon" :src="require('@/assets/img/logo.png')" />
    <img
      v-if="!userInfo"
      class="lt-icon"
      @click="handleGoUser(1)"
      :src="require('@/assets/imgh5/ltbtn.png')"
    />
    <div v-else style="color: #1c75d9; font-size: 0.25rem">
      您好，{{ userInfo.account }}
    </div>

    <!-- h5菜单 -->
    <div
      class="menu-zhe"
      v-if="menuShow"
      @click="
        () => {
          menuShow = false;
        }
      "
    >
      <div class="h5-menu">
        <img class="h5-m-bg" :src="require('@/assets/imgh5/menubg.png')" />

        <div
          class="menu-li"
          :class="activeIndex === '1' ? 'active-li' : ''"
          @click.stop="handleSelect('1')"
        >
          首页
        </div>
        <div
          class="menu-li"
          style="top: 1.48rem"
          :class="activeIndex === '2' ? 'active-li' : ''"
          @click.stop="handleSelect('2')"
        >
          产品中心
        </div>
        <div
          class="menu-li"
          style="top: 2.96rem"
          @click.stop="handleSelect('3')"
          :class="activeIndex === '3' ? 'active-li' : ''"
        >
          关于我们
        </div>
        <div
          class="menu-li"
          style="top: 4.44rem"
          @click.stop="handleSelect('4')"
          :class="activeIndex === '4' ? 'active-li' : ''"
        >
          加入我们
        </div>
        <div
          class="menu-li"
          style="top: 5.92rem"
          @click.stop="handleSelect('5')"
          :class="activeIndex === '5' ? 'active-li' : ''"
        >
          客服中心
        </div>
        <div
          class="menu-li"
          style="top: 7.4rem"
          @click.stop="handleSelect('6')"
          :class="activeIndex === '6' ? 'active-li' : ''"
        >
          用户中心
        </div>
        <div
          class="menu-li"
          style="top: 8.88rem"
          @click.stop="handleSelect('7')"
          :class="activeIndex === '7' ? 'active-li' : ''"
        >
          家长监护
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const activeIndex = ref(store.state.activeIndex || "1");
const router = useRouter();
const menuShow = ref(false);
const href = computed(() => store.state.href);
const activeIndex1 = computed(() => store.state.activeIndex);
watch(activeIndex1, (newV) => {
  activeIndex.value = newV;
});
const userInfo = computed(() => store.state.userInfo);
const userInfo1 = computed(() => store.state.userInfo);
watch(userInfo1, (newV) => {
  userInfo.value = newV;
});
const handleShowMenu = () => {
  menuShow.value = true;
};
const handleGoUser = (n) => {
  store.commit("setActiveIndex", 6);
  router.push({ path: "/user", query: { option: n } });
};
// 菜单点击
const handleSelect = (key) => {
  menuShow.value = false;
  store.commit("setActiveIndex", key);
  sessionStorage.setItem("active", key);
  if (key == "1") {
    if (href.value === "/") return;
    router.push({ path: "/" });
  } else if (key == "2") {
    if (href.value === "/Gamelist") return;
    router.push({ path: "/Gamelist" });
  } else if (key == "3") {
    if (href.value === "/auto") return;
    router.push({ path: "/auto" });
  } else if (key == "4") {
    if (href.value === "/join") return;
    router.push({ path: "/join" });
  } else if (key == "5") {
    if (href.value === "/service") return;
    router.push({ path: "/service" });
  } else if (key == "6") {
    if (href.value === "/user") return;
    router.push({ path: "/user" });
  } else if (key == "7") {
    if (href.value === "/parent") return;
    router.push({ path: "/parent" });
  }
};
</script>

<style scoped>
.imgw145 {
  display: flex;
  align-items: center;
}
.menu-nav {
  position: absolute;
  top: 70px;
  left: 0px;
}
.menu-bug {
  width: 100%;
}
.about-menu .menu-nav {
  display: none;
}
.about-menu:hover .menu-nav {
  display: block;
}
.nav-ul {
  position: absolute;
  left: 0;
  top: 10%;
  width: 100%;
  height: 90%;
}
.nav-li {
  height: 33%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nav-li:hover {
  color: #1c75d9;
}
.menu-index {
  position: absolute;
  width: 6px;
  top: 62px;
  left: calc(50% - 3px);
}
.right-login {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.l-text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #fff;
}
.btn-text {
  cursor: pointer;
}
.tbbgimg {
  width: 120px;
}
.affix-container {
  background: url("../../src/assets/img/topline.png");
  height: 80px;
}
.el-menu {
  width: 80%;
  margin: 0 auto;
  background-color: initial;
  border-bottom: none;
  height: 80px;
}
.flex-grow {
  flex-grow: 1;
}
/deep/.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  color: #1c75d9 !important;
}
.el-menu--horizontal > .el-menu-item {
  color: #474747;
  font-size: 20px;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  color: #474747 !important;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: inherit;
  color: #1c75d9;
  border-bottom: none;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  background-color: inherit;
  color: #1c75d9;
  border-bottom: none;
}
.m-1 {
  margin-top: 30px;
  width: 140px;
}
@media screen and (max-width: 750px) {
  .h5-top {
    width: 100%;
    height: 1rem;
    background: url("../../src/assets/imgh5/topline.png");
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  .top-icon {
    width: 2rem;
  }
  .lt-icon {
    width: 1rem;
  }
  .top-more {
    width: 0.4rem;
  }
  .menu-zhe {
    position: fixed;
    top: 1rem;
    left: 0;
    width: 7.5rem;
    height: calc(100% - 1rem);
    background: #0000005e;
    z-index: 999;
  }
  .h5-menu {
    position: absolute;
    left: 0;
    top: 0;
  }
  .h5-m-bg {
    width: 3rem;
  }
  .menu-li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.47rem;
    line-height: 1.47rem;
    text-align: center;
    color: #474747;
    font-size: 18px;
  }
  .menu-index {
    position: absolute;
    width: 0.2rem;
    top: 0.55rem;
    left: 0;
  }
  .active-li {
    color: #1c75d9;
  }
}
</style>
