<template>
  <div class="footer" v-if="show">
    <img class="logo" :src="require('@/assets/img/logo.png')" />

    <div class="text">
      <div style="margin-bottom: 20px">
        <span class="t-btn" @click="handleGo(2, '/GameList')">产品中心</span>
        <span class="line">|</span>
        <span class="t-btn" @click="handleGo(3, '/auto')">关于我们</span>
        <span class="line">|</span>
        <span class="t-btn" @click="handleGo(4, '/join')">加入我们</span>
        <span class="line">|</span>
        <span class="t-btn" @click="handleGo(5, '/service')">客服中心</span>
      </div>
      <p>
        浙网文[2018]2743-176号
        <a
          style="color: #2e2e2e"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >浙ICP备17055122号</a
        >
      </p>
      <p>
        本公司积极履行<a style="color: #2e2e2e" href="/static/treaty.html"
          >《网络游戏行业防沉迷自律公约》</a
        >
      </p>
      <p>适龄提示：本平台游戏适用于年满18周岁及以上用户</p>
      <p>经营许可证编号：浙B2-20180814 浙公安备案号:33010902002245</p>
      <p>抵制不良游戏，拒绝盗版游戏。 注意自我保护，谨防受骗上当。</p>
      <p>适度游戏益脑，沉迷游戏伤身。 合理安排时间，享受健康生活。</p>
      <p>© 杭州九玩网络科技有限公司 版权所有</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const show = ref(false);
const load = computed(() => store.state.load);
watch(load, (newV) => {
  if (newV == false) {
    setTimeout(() => {
      show.value = true;
    }, 1000);
  } else {
    show.value = false;
  }
});
const handleGo = (n, val) => {
  store.commit("setActiveIndex", n);
  router.push(val);
};
</script>

<style scoped>
.footer {
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 250px;
  font-size: 14px;
  color: #2e2e2e;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.logo {
  width: 150px;
}
.text {
  text-align: left;
  line-height: 20px;
  margin-left: 100px;
}
.line {
  margin: 0 20px;
}
.t-btn {
  cursor: pointer;
}
.t-btn:hover {
  color: #409eff;
}
@media screen and (max-width: 750px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 6rem;
    padding: 0 0;
  }
  .logo {
    width: 3rem;
    margin-bottom: 20px;
  }
  .text {
    font-size: 12px;
    margin-left: 0;
    text-align: center;
  }
}
</style>
