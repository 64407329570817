<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img class="aboutbg1 pc" :src="require('@/assets/img/gamebg1.png')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/gamebg1.png')" />

      <div class="top-font top-font1">网络游戏</div>
      <div class="ct-font">
        <div>JIUWAN GAMES</div>
      </div>
      <div class="top-font top-font2">未成年人家长监护工程</div>
    </div>
    <div class="section2 section" id="section2">
      <div class="contain">
        <div class="left pc">
          <div class="left-top">
            <div
              class="line"
              @click="handleShowDetail(item)"
              v-for="(item, index) in leftList"
              :key="index"
              :class="[
                index == leftList.length - 1 ? '' : 'no-border',
                leftType == item.id ? 'active-li' : '',
              ]"
            >
              <div class="lt-div">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="left-bottom">
            <div class="left-tip">文档下载</div>
            <div
              v-for="(item, index) in leftFile"
              :key="index"
              class="line2"
              @click="handleDownFile(item.down_url)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="right pc" v-html="context"></div>

        <div class="left h5" v-if="!detail">
          <div class="left-top">
            <div
              class="line"
              @click="handleShowDetail(item)"
              v-for="(item, index) in leftList"
              :key="index"
              :class="[
                leftType == item.id ? 'active-li' : '',
                index === leftList.length - 1 ? 'no-border' : '',
              ]"
            >
              <div class="lt-div">{{ item.title }}</div>

              <img
                class="enter-img h5"
                :src="require('@/assets/imgh5/enter.png')"
              />
            </div>
          </div>
          <div class="left-bottom">
            <div class="left-tip">文档下载</div>
            <div
              v-for="(item, index) in leftFile"
              :key="index"
              class="line2"
              @click="handleDownFile(item.down_url)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="right h5" v-if="detail">
          <img
            class="back-btn"
            @click="handleClose"
            :src="require('@/assets/imgh5/btn5.png')"
          />
          <div class="text-contain" v-html="context"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { onMounted, onUnmounted, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();

const leftType = ref(1);
const detail = ref(false);
const webId = ref();
const leftList = ref([]);
const leftFile = ref([]);
const context = ref("");
// 初始化
const initData = async () => {
  return new Promise((reslove) => {
    let params = {
      host: window.location.host, //"www.love9w.com",
    };
    proxy.$axios.post("/Index/initData", params).then(async (res) => {
      if (res.code == 200) {
        webId.value = res.data.company_id;
        await getList();
        await getFile();
        reslove();
      }
    });
  });
};
initData();
// 获取文件列表
const getFile = async () => {
  return new Promise((reslove) => {
    let params = {
      company_id: webId.value,
    };
    proxy.$axios.post("/Index/getFiles", params).then((res) => {
      if (res.code == 200) {
        leftFile.value = res.data;
        reslove();
      }
    });
  });
};
// 获取列表
const getList = async () => {
  return new Promise((reslove) => {
    let params = {
      company_id: webId.value,
    };
    proxy.$axios.post("/Index/getFaqs", params).then((res) => {
      if (res.code == 200) {
        leftList.value = res.data;
        handleShowDetail(leftList.value[0], "load");
        reslove();
      }
    });
  });
};
const handleClose = () => {
  detail.value = false;
};
const handleShowDetail = (item, str) => {
  leftType.value = item.id;
  if (!str) {
    detail.value = true;
  }
  let params = {
    id: item.id,
  };
  proxy.$axios.post("/Index/getFaqContent", params).then((res) => {
    if (res.code == 200) {
      context.value = res.data.content;
    }
  });
};
const handleDownFile = (val) => {
  window.location = val;
};
onMounted(() => {
  store.commit("setLoad", false);
});
onUnmounted(() => {});
</script>
<style src="./parent.css" scoped></style>
<style scoped>
</style>
  