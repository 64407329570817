<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img class="aboutbg1 pc" :src="require('@/assets/img/gamebg1.png')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/gamebg1.png')" />

      <div class="top-font top-font1">客服</div>
      <div class="ct-font">
        <div>JIUWAN GAMES</div>
      </div>
      <div class="top-font top-font2">中心</div>
    </div>
    <div class="section2 section" id="section2">
      <div class="contain">
        <div class="top-line">
          <img class="sbg pc" :src="require('@/assets/img/sbg.png')" />
          <img class="sbg h5" :src="require('@/assets/imgh5/sbg.png')" />
          <div class="s-all">
            <div class="sa-li">
              <img class="sicon pc" :src="require('@/assets/img/qq.png')" />
              <img class="sicon h5" :src="require('@/assets/imgh5/qq.png')" />
              <span class="pc">QQ客服：800833682</span>
              <div class="h5">
                <div>QQ客服：</div>
                <div>800833682</div>
              </div>
            </div>
            <div style="font-size: 30px">|</div>
            <div class="sa-li">
              <img class="sicon pc" :src="require('@/assets/img/kefu.png')" />
              <img class="sicon h5" :src="require('@/assets/imgh5/kefu.png')" />
              <span class="pc" style="cursor: pointer" @click="handleDService"
                >企业客服：九玩网络科技</span
              >
              <div class="h5" @click="handleDService">
                <div>企业客服：</div>
                <div>九玩网络科技</div>
              </div>
            </div>
          </div>
        </div>
        <!-- pc -->
        <div class="bottom-serve">
          <div class="server-ul">
            <div
              class="serve-li"
              v-for="(item, index) in serviceList"
              :key="index"
            >
              <div class="sl-left">
                <img class="gameicon" :src="item.game_icon" />
              </div>
              <div class="sl-right">
                <div class="gname1">《{{ item.game_name }}》</div>
                <img class="lueicon" :src="require('@/assets/img/lue.png')" />
                <div class="desc">
                  {{ item.game_desc }}
                </div>
              </div>
              <!-- 悬浮出现 -->
              <div
                class="sl-float"
                v-if="item.wechart_pic && item.qqList && item.qqList.length > 0"
              >
                <img class="ser-bg" :src="require('@/assets/img/serbg.png')" />

                <!-- 左边 -->
                <div class="ser-left">
                  <img
                    class="erimg"
                    v-if="item.wechart_pic"
                    :src="item.wechart_pic"
                  />
                  <div class="kong" v-else>暂无</div>
                  <div class="er-font">微信公众号</div>
                </div>
                <!-- 右边 -->
                <div class="ser-right">
                  <div class="gname1">《{{ item.game_name }}》</div>
                  <img class="lueicon" :src="require('@/assets/img/lue.png')" />

                  <div
                    class="q-font"
                    v-for="(item2, index2) in item.qqList"
                    :key="index2"
                  >
                    官方玩家Q群{{ index2 + 1 }}：{{ item2 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { onMounted, onUnmounted, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
const webId = ref();
const serviceList = ref([]);
// 初始化
const initData = async () => {
  return new Promise((reslove) => {
    let params = {
      host: window.location.host, //"www.love9w.com"
    };
    proxy.$axios.post("/Index/initData", params).then(async (res) => {
      if (res.code == 200) {
        webId.value = res.data.company_id;
        await getService();
        reslove();
      }
    });
  });
};
initData();
const getService = () => {
  return new Promise((reslove) => {
    let params = {
      company_id: webId.value,
      type: "list",
    };
    proxy.$axios.post("/Index/getGames", params).then((res) => {
      if (res.code == 200) {
        serviceList.value = res.data;
        serviceList.value.forEach((x) => {
          if (x.qq_tel.length > 0) {
            x.qqList = x.qq_tel.split(",");
          }
        });
        reslove();
      }
    });
  });
};
const handleDService = () => {
  window.location = "https://wpa1.qq.com/VlUQieBw?_type=wpa&qidian=true";
};
onMounted(() => {
  store.commit("setLoad", false);
});
onUnmounted(() => {});
</script>
  <style src="./service.css" scoped></style>
  <style scoped>
</style>
  